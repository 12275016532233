<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Attendance List Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center col-12 flex-wrap">
              <!-- <div class="d-flex"> -->
              <div class="me-2">
                <label class="col-form-label me-1">From Date</label>
                <div style="width: 170px">
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    @change="
                      getFilterData(
                        from_date,
                        to_date,
                        moduleName,
                        holding_bu,
                        status,
                        employee
                      )
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">To Date</label>
                <div style="width: 170px">
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    @change="
                      getFilterData(
                        from_date,
                        to_date,
                        moduleName,
                        holding_bu,
                        status,
                        employee
                      )
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Module</label>
                <div style="width: 170px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        moduleName,
                        holding_bu,
                        status,
                        employee
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">HoldingBusiness</label>
                <div style="width: 170px">
                  <v-select
                    v-model="holding_bu"
                    :options="holdingBusiness"
                    label="name"
                    :clearable="false"
                    :selectable="(options) => options.id != holding_bu.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        moduleName,
                        holding_bu,
                        status,
                        employee
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Status</label>
                <div style="width: 170px">
                  <v-select
                    label="name"
                    v-model="status"
                    :options="statusList"
                    :clearable="false"
                    :selectable="(options) => options.id != status.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        moduleName,
                        holding_bu,
                        status,
                        employee
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Employee Name</label>
                <div style="width: 170px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != employee.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        moduleName,
                        holding_bu,
                        status,
                        employee
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>

              <!-- </div> -->
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="dataTable"
                v-show="!loading"
                style="width: 1500px"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th style="width: 150px">Employee ID</th>
                    <th style="width: 200px">Employee Name</th>
                    <th style="width: 150px">Position Level</th>
                    <th>Department</th>
                    <th style="width: 200px">Holding Business</th>
                    <th>Status</th>
                    <th style="width: 150px">From Date</th>
                    <th style="width: 150px">To Date</th>
                    <th>Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in allAttendance" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.employee_id }}</td>
                    <td>{{ item.employee_name }}</td>
                    <td>{{ item.position_level }}</td>
                    <td>{{ item.department }}</td>
                    <td>{{ item.business_unit }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.from_date }}</td>
                    <td>{{ item.to_date }}</td>
                    <td>{{ item.module }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
  },
  data() {
    return {
      from_date: "",
      to_date: "",
      moduleName: "",
      holding_bu: "",
      status: "",
      employee: "",
      allAttendance: [],
      modules: [],
      holdingBusiness: [],
      statusList: [
        { id: 1, name: "Absent" },
        { id: 2, name: "Present" },
      ],
      employeeLists: [],
      headers: [
        "No",
        "Employee ID",
        "Employee Name",
        "Position Level",
        "Department",
        "Holding Business",
        "Status",
        "From Date",
        "To Date",
        "Module",
      ],
      title: "Attendance List Report",
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    async getAllEmployee() {
      this.loading = true;
      await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
        this.employeeLists = response.data.data;
        this.loading = false;
      });
    },
    getFilterData(
      from_date,
      to_date,
      moduleName,
      holding_bu,
      status,
      employee
    ) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let fromDateFilter = from_date;
        let fromDateStage = data[7];
        let toDateFilter = to_date;
        let toDateStage = data[8];
        let moduleFilter = moduleName?.name;
        let moduleStage = data[9];
        let holdingBuFilter = holding_bu?.name;
        let holdingBuStage = data[5];
        let statusFilter = status?.name;
        let statusStage = data[6];
        let employeeFilter = employee?.name;
        let employeeStage = data[2];

        if (fromDateFilter && toDateFilter) {
          const itemDate = new Date(data[8]);
          if (
            itemDate >= new Date(from_date) &&
            itemDate <= new Date(to_date) &&
            (moduleFilter == moduleStage || !moduleFilter) &&
            (holdingBuFilter == holdingBuStage || !holdingBuFilter) &&
            (statusFilter == statusStage || !statusFilter) &&
            (employeeFilter == employeeStage || !employeeFilter)
          ) {
            return true;
          }
          return false;
        } else if (
          (fromDateFilter == fromDateStage || !fromDateFilter) &&
          (toDateFilter == toDateStage || !toDateFilter) &&
          (moduleFilter == moduleStage || !moduleFilter) &&
          (holdingBuFilter == holdingBuStage || !holdingBuFilter) &&
          (statusFilter == statusStage || !statusFilter) &&
          (employeeFilter == employeeStage || !employeeFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#dataTable").DataTable().draw();
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getAllBusinessUnits() {
      await axios
        .get(`${this.baseUrlHRIS}api/holdingbusiness`)
        .then((response) => {
          this.holdingBusiness = response.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          // console.log(this.holdingBusiness, "holding");
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Not Bu/Br found.");
        });
      // await this.getAssessorsByBuBr();
    },
    async getAllAttendance() {
      await axios
        .get(`${this.baseUrl}admin/v2/attendance-list`)
        .then((response) => {
          this.loading = true;
          this.$Progress.start();
          this.allAttendance = response.data.data;
          this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
          this.allAttendance.forEach((item) => {
            this.employeeLists.forEach((emplist) => {
              if (emplist.emp_id == item.employee_id) {
                item.employee_name = emplist.name;
                item.business_unit = emplist.business_unit[0]?.name;
                item.department = emplist.department[0]?.name;
                item.position_level = emplist.position_level[0]?.name;
              }
            });
          });
          this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
          this.holdginBusiness = this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error, "error value is");
        });
      //
      $("#dataTable").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");

      this.pdfLoading = true;
      const sheetData = [];

      // sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });

      exportPdf(this.headers, sheetData, this.title);
      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");

      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });

      exportExcel(sheetData, "Total Attendance");
      this.excelLoading = false;
    },
    refreshData() {
      this.from_date = "";
      this.to_date = "";
      this.moduleName = "";
      this.employee = "";
      this.holding_bu = "";
      this.status = "";
      this.allAttendance = [];
      this.modules = [];
      this.holdingBusiness = [];
      this.employeeLists = [];
      this.clearFilter();
      this.getAllModules();
      this.getAllBusinessUnits();
      this.getAllAttendance();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().draw();
    },
  },
  async created() {
    this.clearFilter();
    if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
      if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
        this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
      } else {
        await this.getAllEmployee();
      }
      await this.getAllModules();
      await this.getAllBusinessUnits();
      await this.getAllAttendance();
    }
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
